exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-free-trial-index-jsx": () => import("./../../../src/pages/free-trial/index.jsx" /* webpackChunkName: "component---src-pages-free-trial-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-discounts-jsx": () => import("./../../../src/pages/info/discounts.jsx" /* webpackChunkName: "component---src-pages-info-discounts-jsx" */),
  "component---src-pages-info-privacy-jsx": () => import("./../../../src/pages/info/privacy.jsx" /* webpackChunkName: "component---src-pages-info-privacy-jsx" */),
  "component---src-pages-info-samples-jsx": () => import("./../../../src/pages/info/samples.jsx" /* webpackChunkName: "component---src-pages-info-samples-jsx" */),
  "component---src-pages-info-services-jsx": () => import("./../../../src/pages/info/services.jsx" /* webpackChunkName: "component---src-pages-info-services-jsx" */),
  "component---src-pages-info-terms-jsx": () => import("./../../../src/pages/info/terms.jsx" /* webpackChunkName: "component---src-pages-info-terms-jsx" */),
  "component---src-pages-my-custom-index-jsx": () => import("./../../../src/pages/my-custom/index.jsx" /* webpackChunkName: "component---src-pages-my-custom-index-jsx" */),
  "component---src-pages-offers-index-jsx": () => import("./../../../src/pages/offers/index.jsx" /* webpackChunkName: "component---src-pages-offers-index-jsx" */),
  "component---src-pages-order-quote-jsx": () => import("./../../../src/pages/order/quote.jsx" /* webpackChunkName: "component---src-pages-order-quote-jsx" */),
  "component---src-pages-success-index-jsx": () => import("./../../../src/pages/success/index.jsx" /* webpackChunkName: "component---src-pages-success-index-jsx" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-velvetverbosity-com-index-jsx": () => import("./../../../src/pages/velvetverbosity-com/index.jsx" /* webpackChunkName: "component---src-pages-velvetverbosity-com-index-jsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-essay-list-categories-js": () => import("./../../../src/templates/essay-list-categories.js" /* webpackChunkName: "component---src-templates-essay-list-categories-js" */),
  "component---src-templates-essay-list-js": () => import("./../../../src/templates/essay-list.js" /* webpackChunkName: "component---src-templates-essay-list-js" */),
  "component---src-templates-essay-post-js": () => import("./../../../src/templates/essay-post.js" /* webpackChunkName: "component---src-templates-essay-post-js" */),
  "component---src-templates-landing-list-js": () => import("./../../../src/templates/landing-list.js" /* webpackChunkName: "component---src-templates-landing-list-js" */),
  "component---src-templates-landing-post-js": () => import("./../../../src/templates/landing-post.js" /* webpackChunkName: "component---src-templates-landing-post-js" */),
  "component---src-templates-pdf-js": () => import("./../../../src/templates/pdf.js" /* webpackChunkName: "component---src-templates-pdf-js" */)
}

